









































































import Vue from 'vue'
import { userModule } from '@/store/user/module'
import Component from 'vue-class-component'
import { AuthRequest, SignupRequest, UserInfo } from '../store/user/types'
import { Watch } from 'vue-property-decorator'
import MButton from '@/components/common/MButton.vue'
import MTextField from '@/components/common/MTextField.vue'
import { ButtonType } from '@/components/common/types'

@Component({
  components: { MButton, MTextField }
})
export default class LoginView extends Vue {
  loginUsername: string = ''
  loginPassword: string = ''

  signupUsername: string = ''
  signupPassword: string = ''
  signupPasswordRepeat: string = ''
  signupBudgetName: string = ''

  users = userModule
  ButtonType = ButtonType

  login(): void {
    if (this.isLoginValid) {
      this.users
        .login(new AuthRequest(this.loginUsername, this.loginPassword))
        .then(() => this.users.retrieveUserInfo())
    }
  }

  signup(): void {
    if (this.isSignupValid) {
      this.users
        .signup(
          new SignupRequest(
            this.signupUsername,
            this.signupPassword,
            this.signupBudgetName
          )
        )
        .then(() => this.users.retrieveUserInfo())
    }
  }

  mounted(): void {
    if (this.$route.query.logout) {
      this.users.logout()
      this.toast()
    }
  }

  toast(): void {
    this.$buefy.toast.open('You have been logged out')
  }

  get isLoginValid(): boolean {
    return this.loginUsername.length > 0 && this.loginPassword.length > 0
  }

  get isSignupValid(): boolean {
    return (
      this.signupUsername.length > 0 &&
      this.signupPassword.length > 0 &&
      this.signupPassword === this.signupPasswordRepeat
    )
  }

  get errorMessage(): string | null {
    return this.users.error
  }

  get userInfo(): UserInfo | null {
    return this.users.userInfo
  }

  @Watch('userInfo')
  onUserInfoChanged(
    newValue: UserInfo | null,
    oldValue: UserInfo | null
  ): void {
    if (newValue && newValue.budgets.length === 1) {
      this.$router.push({
        name: 'transactions',
        params: { budgetId: newValue.budgets[0].id }
      })
    } else {
      this.$router.push({ name: 'start' })
    }
  }
}
